<template>
  <form @submit.prevent="post" class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600">
        <h1 class="my-0">Profile</h1>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 gap-20 col">
      <div class="w600 weight-300">
        Manage your personal information
      </div>
    </section>
    <form ref="formAvatar" class="none" enctype="multipart/form-data">
      <input type="file" @change="upload" name="avatar" ref="fileAvatar" />
    </form>
    <section class="center col py-20">
      <div class="w600 weight-300">Profile avatar</div>
      <div class="relative">
        <div class="profile">
          <img class="pic" v-if="avatar" :src="avatarUrl" />
          <s-icon height="100" v-if="!avatar">account</s-icon>
          <pulse-loader
            :loading="load"
            color="white"
            size="10px"
            class="absolute"
          ></pulse-loader>
        </div>
        <button
          @click="openPicker"
          type="button"
          class="white round camera center opacity-60"
        >
          <s-icon class="">camera</s-icon>
        </button>
      </div>
    </section>
    <section class="center py-20 col gap-20">
      <div class="w600 weight-300">Account Details</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">
          {{ $t("modal.account.first_name") }}
        </div>
        <s-text-field v-model="first_Name" height="30"></s-text-field>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.last_name") }}
        </div>
        <s-text-field v-model="last_Name" height="30"></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20">
      <div class="w600 weight-300">Password</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">
          {{ $t("modal.account.current_password") }}
        </div>
        <s-text-field
          v-model="oldPassword"
          type="password"
          password
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.new_password") }}
        </div>
        <div>
          <s-text-field
            v-model="password"
            bar
            password
            height="30"
            type="password"
          ></s-text-field>
        </div>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.confirm_password") }}
        </div>
        <s-text-field
          v-model="password2"
          type="password"
          password
          height="30"
        ></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20 w100">
      <div class="w600 weight-300">Email</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Contact Email</div>
        <s-text-field
          block
          v-model="emailContact"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">Billing Email</div>
        <s-text-field
          block
          v-model="emailBilling"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">Legal Email</div>
        <s-text-field
          block
          v-model="emailLegal"
          type="text"
          height="30"
        ></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20 w100">
      <div class="w600 weight-300">Company</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Company Name</div>
        <s-text-field
          block
          v-model="companyName"
          type="text"
          height="30"
        ></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20 w100">
      <div class="w600 weight-300">Contact Address</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Address 1</div>
        <s-text-field
          block
          v-model="contactAddress1"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">Address 2</div>
        <s-text-field
          block
          v-model="contactAddress2"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">Zip</div>
        <s-text-field
          block
          v-model="contactZIP"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">City</div>
        <s-text-field
          block
          v-model="contactCity"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">State</div>
        <s-text-field
          block
          v-model="contactState"
          type="text"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">Country</div>
        <s-dropdown
          placeholder="Country"
          :countries="countries"
          height="30"
          v-model="contactCountry"
          class="w100"
        ></s-dropdown>
      </div>
    </section>

    <section class="center py-20 col gap-20">
      <div class="w600 justify-end">
        <s-btn class="green">SAVE</s-btn>
      </div>
    </section>
  </form>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      first_Name: "",
      last_Name: "",
      password: "",
      password2: "",
      oldPassword: "",
      emailContact: "",
      emailBilling: "",
      emailLegal: "",
      companyName: "",
      contactAddress1: "",
      contactAddress2: "",
      contactZIP: "",
      contactCity: "",
      contactState: "",
      contactCountry: 0,
      error: false,
      msg: "",
      load: false,
    };
  },
  methods: {
    async post() {
      let data = {
        firstName: this.first_Name,
        lastName: this.last_Name,
        password: this.password,
        oldPassword: this.oldPassword,
        emailContact: this.emailContact,
        emailBilling: this.emailBilling,
        emailLegal: this.emailLegal,
        companyName: this.companyName,
        contactAddress1: this.contactAddress1,
        contactAddress2: this.contactAddress2,
        contactCity: this.contactCity,
        contactZIP: this.contactZIP,
        emailContact: this.emailContact,
        contactState: this.contactState,
        contactCountry: this.contactCountry,
      };
      let res = await this.$store.dispatch("user/postPartnerInformation", data);

      if (res.status === "ok") {
        this.$store.dispatch("user/getResellerInformation");
      }
    },
    validatePassword() {
      this.error = false;
      this.msg = "";

      if (this.password == "") {
        this.msg = this.$t("resetPassword.password_empty");
        this.error = true;
        return false;
      }
      if (this.password != this.password2) {
        this.msg = this.$t("resetPassword.password_no_not_match");
        this.error = true;
        return false;
      }
      if (this.password.length < 6) {
        this.msg =
          this.$t("resetPassword.password_must_be") +
          6 +
          this.$t("resetPassword.or_more_char");
        this.error = true;
        return false;
      }

      return true;
    },
    async upload(data) {
      let formData = new FormData(this.$refs.formAvatar);
      if (this.checkLimit(formData)) return;
      if (this.checkFileType(formData)) return;
      this.load = true;
      await this.$store.dispatch("user/setResellerProfilePicture", formData);
      await this.$store.dispatch("user/getResellerInformation");
      this.load = false;
    },
    checkLimit(formData) {
      let size = formData.get("avatar").size;
      let limit = 1000000;
      let env = process.env.VUE_APP_LIMIT;
      let mb = (env / limit).toFixed(0);
      if (size < limit) return false;
      this.$store.commit("notifications/push", {
        error: `Image too large, max size: ${mb} MB`,
      });
      return true;
    },
    checkFileType(formData) {
      let ext = formData.get("avatar").type;
      ext = ext.toLowerCase();
      let match = ext.search(/jpg|jpeg|png|gif/);
      if (match >= 0) return false;
      ext = ext.split("/")[1];
      this.$store.commit("notifications/push", {
        error: `File type .${ext} is not supported. Supported file types are as follows: jpg, jpeg, png and gif.`,
      });
      return true;
    },
    openPicker() {
      this.$refs.fileAvatar.click();
    },
  },
  computed: {
    avatarUrl() {
      return process.env.VUE_APP_URL + "/api/reseller/avatar/" + this.avatar;
    },
    user() {
      return this.$store.state.user;
    },
    ...mapState("user", [
      "firstName",
      "lastName",
      "company",
      "tip",
      "avatar",
      "countries",
    ]),
  },
  mounted() {
    this.$store.dispatch("user/getCountries");
    this.first_Name = this.user.firstName;
    this.last_Name = this.user.lastName;

    this.emailContact = this.company.emailContact;
    this.emailBilling = this.company.emailBilling;
    this.emailLegal = this.company.emailLegal;
    this.companyName = this.company.companyName;

    this.contactAddress1 = this.company.contactAddress1;
    this.contactAddress2 = this.company.contactAddress2;
    this.contactZIP = this.company.contactZIP;
    this.contactCity = this.company.contactCity;
    this.contactState = this.company.contactState;
    this.contactCountry = this.company.contactCountry;

    this.$store.dispatch("user/getResellerInformation");
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  max-width: 600px;
  width: 100%;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.w600 {
  max-width: 600px;
  width: 100%;
}
</style>